iframe {
}
.scrollToTop {
  color: #fff;
  background: #878787;
  line-height: 28px;
  /* display: none; */
  font-weight: bold;
  height: 30px;
  width: 30px;
  padding: 0;
  position: fixed;
  right: 10px;
  text-align: center;
  text-decoration: none;
  top: 90%;
  z-index: 10006;
  border-radius: 100%;
  cursor: pointer;
}
.iconsFunction {
  max-width: 26px;
  display: block;
  margin: 0 auto;
  cursor: pointer;
}
[dir="rtl"] .float-left,
html:not([dir="rtl"]) .float-right {
  float: right !important;
}
.ml-auto,
.mx-auto,
html:not([dir="rtl"]) .mfs-auto {
  margin-left: auto !important;
}
.btn-group-toggle > .btn-group > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn input[type="radio"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-success {
  --cui-btn-color: #ffffff !important;
  --cui-btn-hover-color: rgba(255, 255, 255, 0.95) !important;
  --cui-btn-active-color: rgba(255, 255, 255, 0.95) !important;
  --cui-btn-disabled-color: #ffffff !important;
}

.card {
  margin-bottom: 1.5rem;
  background-color: #fafbfc;
}
.widgetcount > .card {
  margin-bottom: 0rem;
}

#root .form-control {
  color: #768192;
  border: 1px solid #d8dbe0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
}

.noBorder .card-accent-info {
  border: 0px !important;
}
.noBorder .card-header {
  padding: 0px !important;
  border: 0px !important;
  height: 0px !important;
}
.noBorder .card-body {
  padding: 0.1rem !important;
}

.salvamenu {
  margin-right: 0.5rem !important;
}
.toast-header {
  color: #815c15 !important;
  background-color: #feefd0 !important;
  border-color: #fde9bd !important;
}
textarea {
  overflow-y: hidden !important;
}
.ContentForm textarea,
.planning textarea {
  overflow-y: auto !important;
}

.header-art p {
  margin-bottom: 0px !important;
  padding-left: 5px;
}

label.btn {
  min-width: 30px !important;
}

.btn.btn-cerca {
  width: 80% !important;
  height: 80% !important;
  vertical-align: middle !important;
  /* display: block; */
  margin-top: 5% !important;
}
.money .col-md-9,
.money .col-md-1 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

header.header {
  box-shadow: 0 2px 2px 0 rgba(60, 75, 100, 0.14),
    0 3px 1px -2px rgba(60, 75, 100, 0.12), 0 1px 5px 0 rgba(60, 75, 100, 0.2) !important;
}
.nav-link.header-nav-link {
  padding: 0.2rem 1rem;
}
.sidebar {
  background-color: #3d4b64 !important;
}
.sidebar .nav-link {
  color: #ffffffcb !important;
}
.sidebar .nav-link.active {
  background-color: #394659 !important;
}
.sidebar .nav-dropdown .nav-link {
  font-size: 0.8rem !important;
  font-weight: normal !important;
}
.sidebar .nav-dropdown > ul .nav-item .nav-link {
  padding-left: 45px !important;
}
.sidebar-fixed .sidebar-nav .nav-link {
  text-wrap: balance !important;
}
.sidebar-fixed.sidebar-narrow-unfoldable .sidebar-nav .nav-link {
  text-wrap: nowrap !important;
}
.sidebar-nav .nav-link {
  font-size: 0.8rem !important;
}

.nav-dropdown-items i {
  display: none !important;
}
.nav-group-toggle svg {
}
.nav-group-toggle {
  font-weight: bold !important;
}

.main {
  background-color: #eaedef !important;
}
.card:not(.plantCard):not(.widgetcount) {
  border: 0px !important;
}

.display-linebreak {
  white-space: pre-line;
}
.display-breakspace {
  white-space: break-spaces;
}
/************** form ************/
.ContentForm {
  background: transparent !important;
  border: none !important;
}
.ContentForm > .card-header {
  background: transparent !important;
  border: none !important;
}
select option:first-child {
  color: #b1b1b1 !important;
  font-style: italic !important;
}

.ContentForm > .card-header h1 {
  color: #2c3f5a !important;
  font-weight: bold;

  font-size: 25px !important;
  letter-spacing: 0px;
}
.ContentForm > .card-header .btn[type="submit"] {
  background: #f7f7f7 0% 0% no-repeat padding-box !important;
  border: 1px solid #676767 !important;
  border-radius: 6px !important;
  opacity: 1 !important;
  color: #676767 !important;
  height: 29px !important;
  width: 29px !important;
  padding: 2px !important;
  font-size: 13px !important;
}
.ContentForm > .card-header .btn-group .btn.btn-primary {
  background: #f7f7f7 0% 0% no-repeat padding-box !important;
  border: 1px solid #676767 !important;
  border-radius: 0px 6px 6px 0px !important;
  opacity: 1 !important;
  color: #676767 !important;
  height: 29px !important;
  width: 29px !important;
  padding: 2px !important;
  font-size: 13px !important;
}
.ContentForm > .card-header .nav-item .dropdown > .btn[type="button"] {
  background: #f7f7f7 0% 0% no-repeat padding-box !important;
  border-top: 1px solid #676767 !important;
  border-bottom: 1px solid #676767 !important;
  border-right: 1px solid #676767 !important;
  border-left: 0px !important;
  border-radius: 0px 6px 6px 0px !important;
  opacity: 1 !important;
  color: #676767 !important;
  height: 29px !important;
  width: 29px !important;
  padding: 2px !important;
  font-size: 13px !important;
}
.ContentForm > .card-header #caret,
.ContentForm > .card-header .btn-group .btnAllegati.btn {
  background: #f7f7f7 0% 0% no-repeat padding-box !important;
  border-top: 1px solid #676767 !important;
  border-bottom: 1px solid #676767 !important;
  border-left: 1px solid #676767 !important;
  border-right: 0px !important;
  border-radius: 6px 0px 0px 6px !important;
  opacity: 1 !important;
  color: #676767 !important;
  height: 29px !important;
  width: 29px !important;
  padding: 2px !important;
  font-size: 13px !important;
  cursor: default !important;
}

h4 {
  font-size: 13px !important;
  font-weight: bold !important;
  color: #000000 !important;
}
.actionGrid thead span {
  height: 5px;
  overflow: auto;
}
.scrollForm button h2 svg {
  margin-right: 4px !important;
}

.ContentForm > .card-header #btnPrint {
  background: #f7f7f7 0% 0% no-repeat padding-box !important;
}
.ucFormRicarica .input-group input.form-control {
  height: 29px !important;
  padding: 7px !important;
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}
.ucFormRicarica input.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b1b1b1 !important;
  opacity: 1; /* Firefox */
  font-size: 12px;
  font-style: italic !important;
}

.ucFormRicarica input.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b1b1b1 !important;
  font-size: 12px;
  font-style: italic !important;
}

.ucFormRicarica input.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b1b1b1 !important;
  font-size: 12px;
  font-style: italic !important;
}

.ucFormRicarica #caricaPerId {
  background-color: #def0d8 !important;
  border: 1px solid #e4e7ea !important;
  border-radius: 0px 6px 6px 0px !important;
  color: #3b3b3c !important;
  height: 29px !important;
  width: 29px !important;
  padding: 2px !important;
  font-size: 13px !important;
}
.table input.form-control[type="number"]:not(:disabled):focus {
  text-align: right; /**/
}
.scrollForm .card > .card-header {
  border: 0px !important;
}
.scrollForm .card .card-header,
.modal-dialog .card .card-header {
  background: #fafbfc 0% 0% no-repeat padding-box !important;

  border-radius: 6px 6px 0px 0px !important;
  opacity: 1 !important;
  /*padding-top: 15px!important;
    padding-bottom: 15px!important;
    */
}
.scrollForm .card-header:empty {
  border: 0px solid #aabfe8 !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.scrollForm .card-header h2,
.scrollForm .card-header h6 {
  color: #2c3f5a !important;
  font-weight: bold;
  font-size: 18px !important;
  letter-spacing: 0px;
}
.scrollForm .card-header h2 svg,
.scrollForm .card-header h6 svg {
  color: #a7c4dd !important;
}
.scrollForm .card-header h2:hover,
.scrollForm .card-header button {
  text-decoration: none !important;
}
.scrollForm div:not(.bg-success) > .card-body {
  background: #fafbfc 0% 0% no-repeat padding-box;
  border: 0px;
  border-top: 0px;
  border-radius: 6px;
  opacity: 1;
}
.form-control:disabled,
.form-control:disabled {
  /* background: #E4E7EA 0% 0% no-repeat padding-box!important;*/
  background: #fff 0% 0% no-repeat padding-box !important;
  border-radius: 6px !important;
  opacity: 1 !important;
  color: #2c3f5a !important;
  cursor: not-allowed;
}
.scrollForm label {
  text-align: left !important;
  font-size: 0.8rem !important;
  letter-spacing: 0px !important;
  /*  color: #000000!important;*/
  opacity: 1 !important;
  font-weight: normal !important;
}

.scrollForm .form-control {
  font-size: 0.8rem !important;
}

input.form-control,
textarea.form-control,
th .form-control {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #e4e7ea !important;
  border-radius: 6px !important;
  opacity: 1 !important;
  color: #2c3f5a !important;
}
.form-select-control,
.form-select-control {
  border: 1px solid #e4e7ea !important;
  border-radius: 6px !important;
  opacity: 1 !important;
  color: #2c3f5a !important;
}

.input-group-prepend .btn,
th .btn {
  background: #def0d8 0% 0% no-repeat padding-box !important;
  border: 1px solid #e4e7ea !important;
  border-radius: 6px 0px 0px 6px !important;
  opacity: 1 !important;
  color: #3b773d !important;
  vertical-align: top;
  height: 100%;
}
.inputGroupLettoreBarcode .btn {
  border: 1px solid #e4e7ea !important;
}
.inputGroupLettoreBarcode .btnkeyboard svg.fa-keyboard {
  width: 0.6875em;
}
.input-group-postpend .btn,
th .btn {
  background: #def0d8 0% 0% no-repeat padding-box !important;
  border: 1px solid #e4e7ea !important;
  color: #3b773d !important;
  border-radius: 0px 6px 6px 0px !important;
  height: 100%;
}

.jeBtnStoreProcedure input.form-control {
  height: auto !important;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.jeBtnStoreProcedure .input-group > .form-select {
  height: 45px !important;
  font-size: 0.8rem !important;
}
.jeBtnStoreProcedure h5 {
  word-wrap: break-word;
}
@media (min-width: 800px) {
  input.form-control,
  th .form-control,
  .jeBtnStoreProcedure .input-group > .form-select {
    font-size: 0.8rem !important;
    height: 25px !important;
    padding: 5px !important;
  }
  textarea.form-control {
    font-size: 0.8rem !important;
    padding: 5px !important;
  }
  .jeBtnStoreProcedure input.form-control,
  .jeBtnStoreProcedure .input-group > .form-select {
    height: 25px !important;
  }
  .jeBtnStoreProcedure .input-group > .form-select {
    line-height: 1;
  }
  .form-select-control {
    font-size: 0.8rem !important;
    /*height: 25px!important;*/
    padding: 5px !important;
  }

  .input-group-prepend .btn,
  th .btn {
    height: 25px !important;
    width: 25px !important;
    font-size: 0.8rem !important;
    padding: 1px !important;
  }
  .input-group .btn.btn-success,
  .input-group .btn.btn-secondary,
  .input-group .btn.btn-danger {
    font-size: 0.8rem !important;
    height: 25px !important;
  }
  .inputGroupLettoreBarcode .btn {
    height: 25px !important;
    width: 25px !important;
    font-size: 0.8rem !important;
    border: 1px solid #e4e7ea !important;
  }
  .input-group-postpend .btn,
  th .btn {
    height: 25px !important;
    width: 25px !important;
    font-size: 0.8rem !important;
    padding: 1px !important;
  }

  .ucRicerca .input-group-postpend:last-child .btn {
    height: 25px !important;
    width: 25px !important;
    font-size: 13px !important;
    padding: 1px !important;
  }
  .ucRicerca .input-group-postpend .btn {
    height: 25px !important;
    width: 25px !important;
    font-size: 13px !important;
    padding: 1px !important;
    line-height: 22px !important;
  }
}
.loginPage input.form-control {
  height: auto !important;
}
.ucRicerca input.form-control,
.inputGroupLettoreBarcode input.form-control,
.input-group input.form-control {
  border-radius: 0px !important;
}

.form-label span {
  margin-left: 5px;
}

.ucRicerca input.form-control {
  border-radius: 0px !important;
}

.scrollForm .input-group input.form-control {
  border-radius: 0px !important;
}
.ucRicerca .input-group-postpend .btn {
  background: #d3d3d3 0% 0% no-repeat padding-box !important;
  border: 1px solid #e4e7ea !important;
  border-radius: 0px !important;
  opacity: 1 !important;
  color: #3b773d !important;

  vertical-align: top;
}
.ucRicerca .input-group-postpend:last-child .btn {
  background: #def0d8 0% 0% no-repeat padding-box !important;
  border: 1px solid #e4e7ea !important;
  border-radius: 0px 6px 6px 0px !important;
  opacity: 1 !important;
  color: #3b773d !important;
  vertical-align: top;
}

button.btnAdd:not(.btnGenData),
button.btnAdd {
  border: 1px solid var(--unnamed-color-3b773d) !important;
  background: #def0d8 0% 0% no-repeat padding-box !important;
  border: 1px solid #3b773d !important;
  color: #3b773d !important;
}
button.btnAdd:hover {
  color: #def0d8 !important;
  background: #3b773d !important;
}

button.btnClone.btn {
  background: #fcf8e3 0% 0% no-repeat padding-box !important;
  border: 1px solid #896d3a !important;
  color: #896d3a !important;
}
button.btnClone.btn:hover {
  color: #fcf8e3 !important;
  background: #896d3a !important;
}

button.btnDel {
  background: #f2dede 0% 0% no-repeat padding-box !important;
  border: 1px solid #a94442 !important;

  color: #a94442 !important;
}
button.btnDel:hover {
  color: #f2dede !important;
  background: #a94442 !important;
}

.btn {
  font-size: 0.8rem !important;
}
.btn.btn-lg,
.btn-group-lg > .btn {
  font-size: 1.25rem !important;
  padding-top: 0.5rem !important;
  padding-left: 1rem !important;
  padding-bottom: 0.5rem !important;
  padding-right: 1rem !important;
  border-radius: 0.5rem !important;
}
.money .col-md-1 {
  padding-left: 5px !important;
}

div:not(.radioSINO) > .radiogroupRow {
  background-color: #ffffff !important;
  background: linear-gradient(180deg, #ffffff 34px, #aabfe8 1px);
}

div:not(.radioSINO) > .radiogroupRow .col-md-12 {
  background: linear-gradient(180deg, #ffffff 35px, #aabfe8 1px) !important;
}

div:not(.radioSINO) > .radiogroupRow .col-md-12 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

div:not(.radioSINO) > .radiogroupRow > .col-md-12 > .row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

div:not(.radioSINO) .radioGroup .btn i {
  margin-left: 4px !important;
  margin-right: 4px !important;
  color: #2c3f5a !important;
  font-size: 13px !important;
}

div:not(.radioSINO) > .radiogroupRow .radioGroup .btn-secondary,
div:not(.radioSINO) > .radiogroupRow .radioGroup .btn-secondary:hover {
  border: 1px solid #aabfe8 !important;
  border-radius: 0px !important;
  border-bottom: 0px !important;
  background-color: #ffffff !important;
  color: #2c3f5a !important;
  font-size: 0.8rem !important;
  height: 33px !important;
}

div:not(.radioSINO) > .radiogroupRow .radioGroup .btn-primary,
div:not(.radioSINO) > .radiogroupRow .radioGroup .btn-primary:hover {
  border: 1px solid #aabfe8 !important;

  border-bottom: 0px !important;
  border-radius: 0px !important;
  background-color: #fafbfc !important;
  color: #2c3f5a !important;
  font-size: 0.8rem !important;
  font-weight: bold !important;
  height: 36px !important;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}
.text-left {
  text-align: left !important;
}

div:not(.radioSINO) .radioGroup .btn svg {
  margin-left: 4px !important;
  margin-right: 4px !important;

  font-size: 13px !important;
}
.ar .radioGroup {
  border-top: 1px solid #aabfe8;
}

.btn-secondary {
  color: #23282c !important;
  background-color: #c8ced3 !important;
  border-color: #c8ced3 !important;
  /*line-height: 1!important;*/
}

.breadcrumb {
  margin-bottom: 0 !important;
  border-bottom: 0 !important;
}
.w-25 {
  width: 25% !important;
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer !important;
}

.dx-g-bs4-sorting-indicator {
  top: 0 !important;
  font-size: 0.8rem !important;
}
.wrapper:not(.wrapper-fluid) .body .main {
  display: flex;
  flex-direction: column;
  flex-grow: 0 !important;
}

.btnCollapseChildren {
  cursor: default !important;
}

.money .col-md-1 {
  padding-left: 0px;
}
.cursor {
  width: 4px;
  /*  background: rgba(50, 31, 219, 0.25);*/
  bottom: 0px;
  position: absolute;
  top: 0px;

  margin-bottom: 0.25rem;
}

.dx-g-bs4-group-panel-empty-message {
  padding: 5px 0 !important;
}
.cardBodyTable {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.scrollForm .cardBodyTable .card-header {
  border-radius: 0px !important;
  padding: 0px !important;
}

.table.dx-g-bs4-table-sticky.dx-g-bs4-table-head {
  background-color: #fafbfc !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.ContentForm > .card-header .btn[type="submit"].isChanged {
  background: #feefd0 0% 0% no-repeat padding-box !important;
  border: 1px solid #815c15 !important;
  color: #815c15 !important;
}
.ContentForm > .card-header .btn[type="submit"].isChanged:hover {
  background: #815c15 0% 0% no-repeat padding-box !important;
  color: #feefd0 !important;
}

.weekDay .card .card-body {
  padding: 0.3rem !important;
  flex: 1 1 auto;
}
.scrollForm {
  overflow-x: hidden !important;
}
.cardForm.planning > .card-body {
  padding: 0px !important;
}
.weekDay .card-header {
  margin-bottom: 0 !important;
  background-color: #f0f3f5 !important;
  border-bottom: 1px solid #c8ced3 !important;
  padding: 0.1rem 0.5rem !important;
}
.weekDay .card-header label {
  line-height: 1.5;
}

.weekDay label,
.weekDay b,
.modal-dialog label {
  font-weight: 600 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  /*line-height: 1.5;*/
  color: #23282c;
  text-align: left;
  margin-bottom: 0 !important;
}
.weekDay:first-child {
  padding-right: 1px !important;
}
.weekDay:last-child {
  padding-left: 1px !important;
}
.weekDay:not(:last-child):not(:first-child) {
  padding-right: 1px !important;
  padding-left: 1px !important;
}
.DayPicker-Day.DayPicker-Day--selected:not(.DayPicker-Day--selectedRange):not(
    .DayPicker-Day--hoverRange
  ) {
  background-color: #fff !important;
  color: #23282c !important;
}
.DayPicker-Months {
  font-size: 0.7rem !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.DayPicker-NavButton {
  top: 0.6em !important;
}

.dettaglioCard {
  max-width: calc(100vw - 300px) !important;
}
.dettaglioCard .row {
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.dettaglioCard dd {
  white-space: pre-wrap;
}

.weekDay .row {
  margin-left: 0px;
  margin-right: 0px;
}
.weekDay .planColumn {
  padding: 5px;
}

.planColumn hr {
  margin-top: 2px;
  margin-bottom: 3px;
}
.weekDay .planColumn .row,
.modal-dialog .row {
  margin-bottom: 5px;
}
.planColumn .card {
  margin-bottom: 0.5rem;
}
.planItem {
  padding: 0px !important;
}

.bg-info.modal-header {
  color: #fff !important;
  background-color: #63c2de !important;
  border-color: #63c2de !important;
}

html:not([dir="rtl"]) .sidebar-nav-link .badge,
html:not([dir="rtl"]) .sidebar-nav-dropdown-toggle .badge {
  margin-left: auto;
  background-color: red;
}

.dropdown-toggle::after {
  vertical-align: 0 !important;
}
.btn {
  padding: 0.04rem 0.35rem !important;
}
.main {
  padding-top: 0.5rem !important;
}
.container-lg,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  padding-right: 3px !important;
  padding-left: 3px !important;
}

table th,
.table td {
  vertical-align: middle !important;
  padding: 0.25rem !important;
  flex-wrap: nowrap !important;
}

html:not([dir="rtl"]) .input-group > .input-group-prepend > .btn-secondary {
  padding-top: 0px !important;
  background: #def0d8 0% 0% no-repeat padding-box !important;
}

html:not([dir="rtl"]) .input-group > .input-group-postpend > .btn-secondary {
  padding-top: 0px !important;
  background: #def0d8 0% 0% no-repeat padding-box !important;
}
.pb-2,
.py-2 {
  padding-top: 0.3rem !important;
  padding-bottom: 0.3rem !important;
}
.page-link {
  padding: 0.3rem 0.35rem !important;
}
.card-header.noheader {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border: 0px !important;
}
.btn-primary.btn {
  background-color: #1d1e1b;
  border-color: #1d1e1b;
  color: #fff !important;
}
label.btn-primary input,
label.btn-primary input svg {
  color: #fff !important;
}
label.btn-primary:hover {
  background-color: #1e5e5e !important;
  border-color: #1e5e5e !important;
  color: #fff !important;
}
.special .btn-group.radioGroup {
  display: flex !important;
}

.special .btn-group.radioGroup .btn {
  flex: 1;
}
.special .btn-group.radioGroup label {
  text-align: center !important;
}
.radioSINO .radioGroup label.btn-primary {
  color: #fff !important;
}
.radioSINO .radioGroup label {
  /*
    padding-top: 5px!important;
    padding-bottom: 5px!important;
    */
  height: 25px;
}
.radioSINO .radioGroup label.btn-primary.btn-disabled,
.radioSINO .radioGroup label.btn-secondary.btn-disabled {
  cursor: not-allowed;
}
.radioSINO .radioGroup label.btn-primary.btn-disabled {
  background-color: #95a5a6 !important;
  border-color: #95a5a6 !important;
  color: #2e3131 !important;
}
.radioSINO .radioGroup label.btn-primary.btn-disabled:hover {
  background-color: #95a5a6 !important;
  border-color: #95a5a6 !important;
}
.radioSINO .radioGroup label.btn-secondary.btn-disabled {
  background-color: #bdc3c7 !important;
  border-color: #bdc3c7 !important;
  color: #4f5d73 !important;
}
.radioSINO .radioGroup label.btn-secondary.btn-disabled:hover {
  background-color: #bdc3c7 !important;
  border-color: #bdc3c7 !important;
}

.btnProcedureD {
  /*
    font-size: x-large!important;
    font-weight: bold!important;
    display: block!important;
    width: 100%!important;
    padding: 0.5rem!important;
    background: #676767 0 0 no-repeat padding-box !important;
    color: #f7f7f7 !important;
    border: 1px solid #676767 !important;
    height: 100%!important;
    border-radius: 0.35rem!important;
    */
}
.btnEseguiProcedura {
  /*padding-top: 30px !important;*/
}
.btnProcedureD:disabled {
  /* opacity: 0.65;*/
}
.btnProcedureD:focus {
  border: 3px solid #9e97e7 !important;
  border-radius: 0.35rem !important;
}
button.btnAnnulla {
  font-size: x-large !important;
  font-weight: bold !important;
  display: block !important;
  width: 100% !important;
  padding: 0.5rem !important;
  background: #a94442 0% 0% no-repeat padding-box !important;
  border: 1px solid #a94442 !important;
  height: 54px !important;
  color: #f2dede !important;
}
button.btnAnnulla:hover {
  color: #f2dede !important;
  background: #a94442 !important;
}
.btnEsegui {
  font-size: x-large !important;
  font-weight: bold !important;
  display: block !important;
  width: 100% !important;
  padding: 0.5rem !important;
  background: #3b773d 0% 0% no-repeat padding-box !important;
  border: 1px solid #3b773d !important;
  color: #def0d8 !important;
}
button.btnEsegui:hover {
  color: #def0d8 !important;
  background: #3b773d !important;
}
.colBtnEsegui {
  padding-bottom: 10px;
}
.btnFisso.btn {
  font-size: large !important;
  font-weight: bold !important;
  background: #2574a9 0% 0% no-repeat padding-box !important;
  border: 1px solid #2574a9 !important;
  display: block !important;
  margin: 28px auto;
  width: 100% !important;
  padding: 0.375rem 0.75rem !important;
  line-height: 1.3 !important;
  height: 40px !important; /*calc(1.5em + 0.65rem )!important;*/
  color: #e4f1fe !important;
}
.btnFisso:hover {
  background: #2574a9 !important;
  color: #e4f1fe !important;
}

.valori-frequenti .btn {
  padding: 0.3rem 0.35rem !important;
}

.valori-frequenti .divvalue {
  padding-top: 30px !important;
}

.procedureDinamiche .input-group svg {
  font-size: 1rem !important;
}
.procedureDinamiche .input-group .btn,
.procedureDinamiche .input-group .input-group-postpend:last-child .btn,
.procedureDinamiche .input-group .form-control {
  height: 40px !important;
}
@media (max-width: 800) {
  .procedureDinamiche .InputProceduraDinamica {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .btnEseguiProcedura {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
.lockValue.col-md-2 {
  /*padding-left: 0px!important;
    height: 40px !important;*/
}
.attivo label {
  font-weight: bold !important;
  color: #000000 !important;
}
.disattivo label {
  font-style: italic;
}
.scrollForm .attivo .form-control:disabled {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}

.procedureDinamiche .scrollForm input.form-control,
.procedureDinamiche th .form-control {
  height: 40px !important;
}
.checkBtnStoreProcedure {
  min-width: 75vh;
}

.list-group-item {
  cursor: pointer;
}
a.header-brand.titolopagine {
  width: 150px !important;
  padding-top: 0px;
}
a.header-brand.titolopagine p {
  font-size: 0.8rem !important;
}
@media (max-width: 340px) {
  .btnFisso.btn {
    font-size: small !important;
    padding: 0.175rem 0.35rem !important;
  }
}
@media (max-width: 384px) {
  .btnFisso.btn {
    margin: 35px auto;
  }
}
@media (max-width: 392px) {
  .btnFisso.btn {
    margin: 35px auto;
  }
}
@media (min-width: 400px) {
  a.header-brand.titolopagine p {
    font-size: 1rem !important;
  }
}
@media (max-width: 400px) {
  body {
    font-size: 1rem !important;
  }
  /* a.header-brand{
         width:100px!important;
    } */
  a.header-brand.titolopagine {
    width: 180px !important;
  }
  .header-nav .nav-link {
    margin-top: 0;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .btnAnnulla,
  .btnEsegui {
    font-size: large !important;
  }

  .btnFisso {
    font-size: medium !important;
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    position: relative !important;
    width: auto !important;
    pointer-events: none !important;
    /* max-width: 60%!important;*/
  }

  .modal-header {
    font-size: 25px;
  }
}
@media (max-width: 596px) {
  .btnFisso.btn {
    margin: 30px auto;
  }
}
@media (min-width: 600px) {
  body {
    font-size: 0.8rem !important;
  }
}
@media (min-width: 768px) {
  .main > .container-lg,
  .main > .container-sm,
  .main > .container-md,
  .main > .container-lg,
  .main > .container-xl {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
}
@media (min-width: 992px) {
  html:not([dir="rtl"])
    .sidebar.sidebar-lg-show:not(.sidebar-right).sidebar-fixed
    ~ .header-wrapper
    header,
  html:not([dir="rtl"])
    .sidebar.sidebar-show:not(.sidebar-right).sidebar-fixed
    ~ .header-wrapper
    header,
  html:not([dir="rtl"]) .wrapper-Maximized ~ .wrapper {
    margin-left: 256px !important;
    transition: margin-left 0.35s, margin-right 0.35s, width 0.35s, flex 0.35s,
      -ms-flex 0.35s !important;
  }

  html:not([dir="rtl"])
    .sidebar.sidebar-lg-show:not(.sidebar-right).sidebar-fixed.sidebar-minimized
    ~ .header-wrapper
    header,
  html:not([dir="rtl"])
    .sidebar.sidebar-show:not(.sidebar-right).sidebar-fixed.sidebar-minimized
    ~ .header-wrapper
    header,
  html:not([dir="rtl"]) .wrapper-Minimized ~ .wrapper {
    margin-left: 56px !important;
    transition: margin-left 0.35s, margin-right 0.35s, width 0.35s, flex 0.35s,
      -ms-flex 0.35s !important;
  }
  html:not([dir="rtl"]) .sidebar:not(.sidebar-right) ~ .header-wrapper header {
    transition: margin-left 0.35s, margin-right 0.35s, width 0.35s, flex 0.35s,
      -ms-flex 0.35s !important;
  }
  html:not([dir="rtl"]) .wrapper-Hidden ~ .wrapper {
    margin-left: 0px !important;
    transition: margin-left 0.35s, margin-right 0.35s, width 0.35s, flex 0.35s,
      -ms-flex 0.35s !important;
  }
}
@media (max-width: 1090px) {
}
@media (max-width: 1490px) {
}
@media (min-width: 1400px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1390px !important;
  }
}
@media (min-width: 1500px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1480px !important;
  }
}
@media (min-width: 1600px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1580px !important;
  }
}
@media (min-width: 1700px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1680px !important;
  }
}
@media (min-width: 1800px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1780px !important;
  }
}
@media (min-width: 1900px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1880px !important;
  }
}
.card-body h5 {
  padding-top: 1rem;
}
.card-body h3 {
  padding-top: 1.5rem;
}

html:not([dir="rtl"]) .card-header-actions {
  float: right;
  margin-right: -0.25rem;
}
.card-header-actions {
  display: inline-block;
}
.scrollForm .card .planItem .card-header {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.scrollForm .weekDay .card .card-header {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
textarea.form-control {
  /* font-size: 0.8rem!important;*/
}
.radioGroup {
  display: inline-block !important;
}
.radioGroup label {
  font-size: 0.8rem !important;
}
.btn {
  font-size: 0.8rem !important;
}
.widgetcount a {
  width: 100%;
  display: block;
}

.border-top-3.card {
  border-top: 3px solid var(--cui-card-border-color, rgba(0, 0, 21, 0.125)) !important;
}
.border-top-3.card.border-top-warning {
  border-top-color: #f9b115 !important;
}
.border-top-3.card.border-top-info {
  border-top-color: #39f !important;
}
.border-top-3.card.border-top-danger {
  border-top-color: #e55353 !important;
}

.procedureDinamiche .col-md-8 > .row {
  margin-top: 10px;
}
.dx-g-bs4-group-cell span > div {
  display: inline;
}
input.ricercaInput {
  width: 100% !important;
  padding: 0.2rem 0.2rem !important;
}
.searchPanel ul.actions-EditTable,
.searchPanel ul.actions-EditTable li {
  width: 100%;
}
.cardFilterDinamic .collapse > .cardBodyTable {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
input.react-tag-input__input::placeholder {
  font-size: x-small;
}
.customToolbar .react-tag-input {
  border: 0px;
  background-color: transparent;
}
.editTableCard .dx-g-bs4-fixed-group-cell {
  overflow: visible;
}
@media (max-width: 767px) {
  .editTableCard .table tr td:last-child:empty {
    width: 0px !important;
  }
}
.dx-g-bs4-table-summary-item {
  /* font-size: xx-small;
    vertical-align: baseline;*/
}
.dx-g-bs4-table-summary-item div {
  /*line-height: 5px;*/
}
.dx-g-bs4-table-summary-item span {
  /*font-size: small;*/
}
.editTableCard td .ucRicerca.mb-2 {
  margin-bottom: 0 !important;
}
.editTableCard th .ucRicerca {
  float: right;
  width: calc(100% - 25px);
  margin-bottom: 0 !important;
}
.editTableCard th .ucRicerca .row {
  margin-left: 0;
}
.editTableCard thead th .ucRicerca .form-group .input-group {
  margin-top: 0;
  margin-bottom: 0 !important;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.body .modal-dialog .div {
  z-index: 100000 !important;
}
.react-datepicker-popper {
  z-index: 3000 !important;
}
.progressbar {
  border: 1px solid #d8dbe0 !important;
  margin-left: 10px !important;
  margin-right: 15px !important;
  margin-bottom: 2px;
  width: calc(100% - 10px) !important;
  border-radius: 3px;
  background: repeating-linear-gradient(
    45deg,
    #d3d3d3,
    #d3d3d3 10px,
    #f0f0f0 0,
    #f0f0f0 20px
  );
}

.progressbar-full {
  border: 1px solid #d8dbe0 !important;
  margin-bottom: 2px;
  width: 100%;
  border-radius: 3px;
  background: repeating-linear-gradient(
    45deg,
    #d3d3d3,
    #d3d3d3 10px,
    #f0f0f0 0,
    #f0f0f0 20px
  );
}
.dx-g-bs4-sorting-control-text,
.dx-g-bs4-table-tree-content,
.dx-g-bs4-table-cell,
.dx-rg-bs4-table-header-title {
  white-space: break-spaces;
}
#root select.form-control,
.modal select.form-control {
  height: 22px;
  font-size: 0.8rem !important;
  line-height: 12px;
  padding: 2px !important;
  border-color: #e4e7ea;
}
#root select.form-select {
  font-size: 0.8rem !important;
  border-color: #e4e7ea;
}

.modificato.btn.btn-success {
  background-color: #a94442;
  font-weight: 700;
  border: 1px #a94442;
}
.page-link {
  font-size: 0.8rem !important;
}
.cardFilterDinamic .card-body {
  padding-bottom: 0px;
}
.float-right.headergrid {
  width: 100%;
  text-align: right;
}
.modal-fullscreen .modal-body {
  padding-top: 0px;
  padding-bottom: 0px;
}
.modal-fullscreen .modal-header {
  padding-top: 5px;
  padding-bottom: 5px;
}
.modal-fullscreen .card .card-header {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.modal-fullscreen .cardBodyTable {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.header.header-sticky {
  padding: 0 !important;
  margin: 0 !important;
}
.header,
.header .container-fluid:first-child {
  min-height: 2rem !important;
  --cui-header-padding-x: 0.5rem;
  --cui-header-padding-y: 0rem;
  --cui-header-min-height: 2rem;
}

@media (min-width: 300px) {
  .header .container-fluid:first-child {
    max-height: 80px;
  }
}
.header .titolopagine {
  padding-bottom: 0;
  margin-bottom: 0;
}
select.setValue {
  box-shadow: 0 0 0 0.25rem rgba(255, 0, 0, 0.45);
}

.table > thead > * > * .input-group:empty {
  margin-top: 0;
  margin-bottom: 0;
}

.table > thead > * > th:has(.input-group:empty),
.table > thead > * > td:empty {
  height: 0;
  padding: 0 !important;
}
/*edit table*/

.editTableCard > .cardBodyTable {
  background: #fafbfc 0% 0% no-repeat padding-box !important;
  border: 0px !important;
  border-radius: 0px 0px 6px 6px !important;
}

.editTableCard .card-header h6 {
  color: #2c3f5a !important;
  font-weight: bold;
  font-size: 18px !important;
  letter-spacing: 0px;
}

.editTableCard .card-header input {
  height: 29px !important;
  padding: 7px !important;
}

.editTableCard > .cardBodyTable .oi:empty:before {
  width: 1em !important;
  text-align: center !important;
  -webkit-box-sizing: content-box !important;
  box-sizing: content-box !important;
}

.editTableCard .card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px) !important;
}

.editTableCard .card-footer,
.editTableCard > .cardBodyTable .card-header {
  padding: 0.5rem 1.25rem !important;
  border-top: 1px solid #c8ced3 !important;
}

.editTableCard .card-footer nav {
  -webkit-box-shadow: 0 2px 2px 0 rgba(60, 75, 100, 0.14),
    0 3px 1px -2px rgba(60, 75, 100, 0.12), 0 1px 5px 0 rgba(60, 75, 100, 0.2) !important;
  box-shadow: 0 2px 2px 0 rgba(60, 75, 100, 0.14),
    0 3px 1px -2px rgba(60, 75, 100, 0.12), 0 1px 5px 0 rgba(60, 75, 100, 0.2) !important;
}
.EditTableRow .card-header,
.EditTableRow .card-footer,
.editTableCard .card-footer {
  background: transparent !important;
}

.container-lg .editTableCard h6 {
  color: #2c3f5a !important;
  font-weight: bold;
  font-size: 18px !important;
  letter-spacing: 0px;
  line-height: 30px;
}
.container-lg .editTableCard .collapse .cardBodyTable {
  background: #fafbfc 0% 0% no-repeat padding-box !important;
  /*border: 1px solid #AABFE8!important;*/
  border-radius: 6px !important;
}

.modal-dialog .editTableCard .collapse .cardBodyTable {
  border: 0px !important;
}
.editTableCard > .card-header {
  background: #fafbfc 0% 0% no-repeat padding-box !important;
  border: 1px solid #aabfe8 !important;
  border-radius: 6px 6px 0px 0px !important;
  opacity: 1 !important;
  padding-top: 10px !important;
  padding-bottom: 5px !important;
}

.editTableCard .card-header input {
  height: 29px !important;
  padding: 7px !important;
}
.editTableCard .card-header input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b1b1b1 !important;
  opacity: 1; /* Firefox */
  font-size: 12px;
  font-style: italic !important;
}

.editTableCard .card-header input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b1b1b1 !important;
  font-size: 12px;
  font-style: italic !important;
}

.editTableCard .card-header input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b1b1b1 !important;
  font-size: 12px;
  font-style: italic !important;
}

.editTableCard .card-header h6 i {
  float: right !important;
  color: #a7c4dd !important;
}

.editTableCard .card-body .button-print {
  background-color: transparent;
  color: #6c7a89;
}
.editTableCard
  .card-body
  tr[style="background-color: rgb(22, 160, 133); color: white;"]
  .button-print {
  color: #ffffff;
}

.editTableCard
  .card-body
  tr[style="background-color: rgb(128, 128, 128); color: black;"]
  .button-print {
  color: #000;
}
.editTableCard .card-body .button-print.red {
  color: #ffffff;
  background-color: #b74f0d;
}

.editTableCard .table thead tr:last-child th {
  padding: 0.1rem !important;
  border: 0px !important;
}
.editTableCard .table th {
  padding: 0.4rem !important;
  font-weight: bold;
}
.editTableCard .table tr:nth-child(3) th,
.editTableCard .table tr:nth-child(3) th div {
  padding: 0 !important;
  overflow: auto;
}
.editTableCard .table td {
  padding: 0.3rem !important;
  position: relative;
}
.editTableCard .table .dx-g-bs4-table-invisible-row td {
  padding: 0rem !important;
}
.editTableCard .card-body .btn {
  /*padding: 0rem!important;
    padding: 0.2rem 0.2rem !important;*/
}

.editTableCard .table th .input-group {
  flex-wrap: nowrap !important;
  width: 96% !important;
  padding: 0px 0px 0.1rem 0.1rem !important;
}

.editTableCard .card-body .cellamasterdetail .btn.btn-showdetail svg {
  /* margin: 0!important;
    vertical-align: top!important;*/
}

.collapseEditTableCard .editTableCard {
  /*margin-bottom: 0.5rem!important;*/
}

.editTableCard .card-body .cellamasterdetail .btn.btn-showdetail {
  /*border-color:transparent;*/
}

.EditTableRow .editTableCard > .card-header,
.EditTableRow .editTableCard.card {
  background-color: transparent !important;
  border: 0px !important;
}
.EditTableRow .editTableCard > .card-header {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0.5rem !important;
}
.EditTableRow .editTableCard > .card-header h6 {
  color: #2c3f5a !important;
  font-weight: bold;
  font-size: 25px !important;
  letter-spacing: 0px;
}

.EditTableRow .card-body.scrollForm {
  padding-top: 0px;
}
.EditTableRow .editTableCard > .card-header .btn {
  background: #f7f7f7 0% 0% no-repeat padding-box !important;
  border: 1px solid #676767 !important;
  border-radius: 6px !important;
  opacity: 1 !important;
  color: #676767 !important;
  height: 29px !important;
  font-size: 13px !important;
}

.editTableCardFilter .cardFilterDinamic .filtri .btn {
  background-color: transparent !important;
  color: #23282c !important;
  border: 0px !important;
  padding: 0px !important;
}
.editTableCardFilter .cardFilterDinamic .filtri .form-text {
  margin-top: 0px !important;
}
.container-lg .cardFilterDinamic .editTableCard .collapse .cardBodyTable {
  border: 0px !important;
}

.editTableCard .table td {
}

.editTableCard .dx-g-bs4-sorting-indicator {
  margin-right: 3px !important;
  margin-left: 3px !important;
}
.editTableCard,
.editTableCardFilter {
  margin-bottom: 0px;
}

.editTableCard .card,
.editTableCardFilter .card {
  margin-bottom: 0px;
}
.editTableCard .cardFilterDinamic,
.editTableCardFilter .cardFilterDinamic {
  padding-bottom: 0px;
}
.react-tag-input {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-radius: 6px !important;
  min-height: auto !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  height: 25px;
}
.react-tag-input .react-tag-input__input {
  height: 25px;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.react-tag-input__input::placeholder {
  font-size: 0.8rem !important;
}

.editTableCard button svg {
  margin: auto !important;
}
.numeroInGriglia {
  text-align: right;
  display: block;
  padding-right: 15px !important;
}
.cellamasterdetail .colCellaMaster.cella2Master,
.cellamasterdetailperc .colCellaMaster.cella2Master {
  padding-left: 0 !important;
  min-height: 16px;
}
.progressbar-avanzamento {
  border-radius: 3px;
}
.cellamasterdetail .colCellaMaster label,
.cellamasterdetailperc .colCellaMaster label {
  min-height: 16px;
}
.cellamasterdetail label,
.cellamasterdetailperc label {
  margin: 0 !important;
}
.cellamasterdetail {
  /*height: 98%;*/
  /*width: 95%;*/
}
.cellamasterdetail .mdCellaBtn {
  /*height: 98%;*/
}
.cellamasterdetail a,
.cellamasterdetailperc a {
  height: 100%;
  width: 100%;
  text-align: center;
  min-height: 100%;
  /*padding-top: 5px!important;
    padding-bottom: 5px!important;*/
}
.cellamasterdetail a div,
.cellamasterdetailperc a div {
  margin: 0 AUTO;
  text-align: center;
  width: 100%;
}
.editTableCard .cellamasterdetail .btn,
.editTableCard .cellamasterdetailperc .btn {
  margin: 0 AUTO;
  text-align: center;
  width: 100%;
  /* padding-top: 5px!important;
    padding-bottom: 5px!important;*/
}

.cellamasterdetail {
  font-size: smaller !important;
}
.editTableCard .colBtnSp {
  height: 100%;
  display: table;
}
.editTableCard .colBtnSp .btn {
  min-height: 100%;
  width: 100%;
  display: table-cell;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.tags-group {
  width: 100%;
}
.btnFooterModal .btn {
  margin: 0 0.4rem 0;
  padding: 0.45rem !important;
  font-size: medium !important;
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}
.btnFooterModal .btn.reclamaFase {
  margin-left: 0;
  margin-right: 0.8rem;
  margin-top: 0;
  margin-bottom: 0;
}
.btnFooterModal .btn.dichiaraFase {
  margin: 0 auto;
  margin-bottom: 5px;
  display: block;
}

.paginaMasterdetail .actionBtn {
  margin-bottom: 5px;
}
.paginaMasterdetail .actionBtn .btn {
  padding: 0.375rem 0.75rem !important;
}

@media (min-width: 800px) {
  .paginaMasterdetail .actionBtn .btn {
    padding: 5px !important;
  }
}

.info-card-pannello .row {
  margin-bottom: 0;
}

.bootstrapDD .dropdown-trigger {
  border-radius: 0.25rem;
}

.bootstrapDD .dropdown-trigger > span:after {
  font-size: 12px;
  color: #555;
}

.bootstrapDD .toggle {
  font: normal normal normal 12px/1 FontAwesome;
  color: #555;
}

.bootstrapDD .toggle.collapsed::after {
  content: "\f067";
}

.bootstrapDD .toggle.expanded::after {
  content: "\f068";
}

.bootstrapDD .root {
  padding: 0px;
  margin: 0px;
}

.bootstrapDD .leaf label {
  max-width: 160px;
  display: inline-block;
  font-weight: normal;
}
.bootstrapDD .leaf label span {
  text-wrap: wrap;
}

.bootstrapDD .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow {
  padding-left: 0;
}
.bootstrapDD .infinite-scroll-component {
  overflow-x: hidden;
}
.bootstrapDD.react-dropdown-tree-select .dropdown .dropdown-trigger {
  border: 0px;
}
.bootstrapDD.react-dropdown-tree-select .dropdown .dropdown-content {
  z-index: 1000;
  min-width: 350px;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger {
  line-height: normal !important;
  max-height: unset !important;
  display: flow !important;
  text-decoration: none;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
  color: rgba(44, 56, 74, 0.95) !important;
}
.bootstrapDD .tag {
  border-radius: 6px;
  background: rgb(225, 225, 225);
  color: rgb(51, 51, 51);
  border: 0px;
  padding: 0px 0px 0px 4px;
}
.bootstrapDD .tag-remove {
  background: rgb(212, 212, 212);
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  height: 2em;
  width: 2em;
  font-size: 0.85em;
  line-height: 0.5em;
  color: rgb(51, 51, 51);
  margin-left: 2px;
  font-weight: bolder;
  text-transform: uppercase;
}
.bootstrapDD .placeholder {
  background-color: transparent;
  color: rgb(51, 51, 51);
}
.react-dropdown-tree-select.bootstrapDD .dropdown .dropdown-trigger {
  padding: 0;
}
.logComponent .table tbody tr {
  border-bottom: 0px solid white;
}

/*MES RECLAMA DICHIARA FASE */
.dichiarastampa p {
  margin-bottom: 0px;
}
.btnRecliamaDichiara .btn {
  margin: 0 0.4rem 0;
  padding: 0.45rem !important;
  font-size: medium !important;
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}
.btnRecliamaDichiara .btn.reclamaFase {
  margin-left: 0;
  margin-right: 0.8rem;
  margin-top: 0;
  margin-bottom: 0;
}
.btnRecliamaDichiara .btn.dichiaraFase {
  margin: 0 auto;
  margin-bottom: 5px;
  display: block;
}
.dichiarastampa h5 {
  font-size: 1rem;
}
.dichiarastampa select {
  width: auto;
  float: left;
  max-width: 90px;
  margin-right: 5px;
}
.dichiarastampa .form-control:focus {
  box-shadow: 0 0 0 1px rgba(0, 194, 16, 1);
}

.dichiarastampa .card {
  margin-bottom: 2px;
}
.dichiarastampa .card-body {
  padding: 0.3rem;
}

.dichiarastampa p {
  overflow: hidden;
}
.dichiarastampa .lettoreBarcode {
  clear: both;
}

/*HR MONITOR PRESENZE*/
.image img {
  max-width: 150px;
}
.userCard .image img {
  max-width: 100%;
}
.userCard .userButton.btn {
  padding: 0 !important;
}
.userCard .info {
  min-height: 90px;
}
.userCard h3 {
  padding-top: 0.7rem;
  margin-bottom: 0rem;
  font-size: 1rem;
  color: #23282c;
}
.userCard h5 {
  padding-top: 0rem;
  margin-bottom: 0rem;
  font-size: 0.8rem;
  color: #676767;
}

.userCard h6 {
  color: #2e3131;
}
.userCard .badge h6 {
  color: #ffffff;
}
.statusUser {
  position: absolute;
  margin-top: 140px;
  margin-left: 120px;
  max-width: 150px;
}
.btn-lg {
  padding: 0.5rem 1rem !important;
  font-size: 1.25rem !important;
  border-radius: 0.3rem !important;
}
.modalCard {
  text-align: center;
}
.modalCard .image {
  max-width: 150px;
  margin: 0 auto;
}

.headerBadge h1 {
  margin-bottom: 0px;
}
.headerBadge .col > div {
  padding-top: 12px;
  height: 58px;
}
.headerBadge .badge {
  margin: 0;
}
.headerBadge .col > div input {
  display: none;
}
@media (min-width: 576px) {
  .userCard .info {
    min-width: 130px;
  }
  .userCard .info {
    min-height: 150px;
  }
  .monitorGestioneHR .userCard .info {
    min-height: 160px;
  }
  .modalCard {
    text-align: left;
  }
  .headerBadge .col > div input {
    width: 100px;
    display: block;
  }
}

@media (min-width: 1270px) {
  .display-4 {
    font-size: 2.5rem !important;
  }
  .headerBadge .col > div {
    height: 35px;
  }
  .headerBadge .col > div input {
    height: 25px;
  }
  .userCard h3 {
    font-size: 0.8rem !important;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .userCard .card-body {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .userCard .card-body .badge {
    padding-top: 3px;
    padding-bottom: 3px;
  }
}
@media (min-width: 700px) {
  .userCard h3 {
    font-size: 1rem;
  }
  .userCard h5 {
    font-size: 0.5rem;
  }
  .userCard .info {
    min-width: 150px;
  }
  .userCard .info {
    min-height: 80px;
  }
  .monitorGestioneHR .userCard .info {
    min-height: 160px;
  }
  .headerBadge .col > div input {
    width: 300px;
    display: block;
  }
}
@media (min-width: 1535px) {
  .monitorGestioneHR .userCard .info {
    min-height: 123px;
  }
}

#ddAllegati .btn {
  padding: 0.04rem 0.7rem !important;
}
.editTableCard td:has(.allegatiCell) {
  overflow: visible;
}
.dropdown-item {
  cursor: pointer;
}
.jeformObjHeader,
.EditTableRow .editTableCard > .card-header.jeEditTableHeader,
.editTableCardFilter .jeEditTableHeader {
  padding-bottom: 0px !important;
}
.jeformObjBody.card-body {
  padding-top: 0px;
}
.EditTableRow .scrollForm {
  padding-bottom: 0;
}

.attachFileName.btn {
}
.imageInTable {
  max-width: 100px;
  max-height: 19px;
}
span {
  cursor: default;
}

td:has(> div.attachColumn) {
  overflow: visible;
}
.filtriGriglia {
  padding-bottom: 5px;
}
.collapseCard .card-body {
  padding-top: 0;
}
.collapseCard .card-body .row {
  /* padding-bottom: 3px;*/
}
.modalBodyUcRicerca tr,
.modalBodyUcRicerca tr > *,
.modalBodyUcRicerca tr td,
.modalBodyUcRicerca tr span {
  cursor: pointer !important;
}
.modalBodyUcRicerca .card-header {
  border: 0px;
}
.editTableCard span,
.editTableCard td {
  cursor: default !important;
}

.table > tbody > * > * {
  border-bottom: 0px;
}
.table > thead > * > * .input-group {
  margin-top: 5px;
  margin-bottom: 5px;
}
body .table > thead > tr:nth-child(3) > * {
  border-bottom-width: 0px;
}
/*
.inputBarcodeHeader{
    width: calc(100vw - 102px);
}
*/
.inputBarcodeHeader .btnShow .barcodeScanner {
  top: 0;
  width: calc(100% - 160px);
  z-index: 1000000;
  /* top: 0; */
  position: -webkit-sticky;
  position: sticky;
  left: 40px;
  position: absolute;
  /* padding: 10px; */
  background-color: #fff;
  height: 39px;
  padding-top: 5px;
  padding-right: 5px;
  padding-left: 5px;
}
.rotatePin {
  transform: rotate(-45deg);
}
.inputBarcodeHeader .btnShow.close .barcodeScanner {
  display: none;
  width: 0;
}
.inputBarcodeHeader .showscanner {
  font-size: 0.8rem !important;
  height: 25px !important;
}
.sidebar .sidebar-brand {
  flex: 0 0 42px;
}

/*statusLavorazioniMacchine*/
.btn-link .statusLavorazioniMacchine,
.btn-link .statusLavorazioniMacchine *:hover {
  color: #23282c;
}
.statusLavorazioniMacchine figure {
  overflow: hidden;
  text-overflow: ellipsis;
  /* width: calc(100% - 34px);*/
}

.statusLavorazioniMacchine .leftAlign figure {
  width: calc(100% - 34px);
}
.statusLavorazioniMacchine .leftAlign.conImg figure {
  width: calc(100% - 70px);
}
.statusLavorazioniMacchine .display-linebreak {
  white-space: nowrap;
}

.statusLavorazioniMacchine figure,
.statusLavorazioniMacchine figure > * {
  margin-bottom: 0;
}
.activeStatus,
.statusLavorazioniMacchine {
  border-radius: 6px;
}
.activeStatus .btn {
  /*height:100%;*/
  background-color: transparent;
  border-color: transparent;
  padding: 0px !important;
}
.activeStatus .btn:hover {
  background-color: transparent;
  border-color: transparent;
}

.activeStatus {
  cursor: pointer;
  border-radius: 6px;
}

.documenti .actionBtn {
  min-height: 25px;
}

.activeStatus {
  max-height: 32px;
  overflow: hidden;
}

.activeStatus .col.description {
  line-height: 32px;
}

.activeStatus img {
  line-height: 32px;
  max-height: 24px;
  max-width: 24px;
  margin: 4px;
}

.activeStatus p {
  font-size: medium;
  line-height: 32px;
}

.activeStatus svg {
  height: 32px;
}

@media (max-width: 1700px) {
  .activeStatus p {
    font-size: small;
    line-height: 32px;
  }
}

@media (max-width: 1200px) and (min-width: 767px) {
  .activeStatus p {
    font-size: x-small;
    line-height: 32px;
  }
}

@media (max-width: 767px) {
  .infoCommessa.h-100 {
    height: auto !important;
  }
  .infoCommessa > div > div:first-child {
    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
  }
  .infoCommessa > div > div:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .infoCommessa > div > div:nth-child(2) {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .infoCommessa > div:first-child {
    margin-bottom: 0.25rem;
  }
  .infoCommessa > div:nth-child(2) {
    margin-bottom: 0.25rem;
  }
}
@media (min-width: 767px) {
  .infoCommessa div:first-child {
    margin-right: 0px;
    padding-right: 0px;
  }
  .infoCommessa div:last-child {
    margin-left: 0px;
    padding-left: 0px;
  }
  .infoCommessa div:nth-child(2) {
    margin-left: 0px;
    padding-left: 0px;
    margin-right: 0px;
    padding-right: 0px;
  }
}
.table {
  margin-bottom: 0px !important;
}
.jeSPBtnModalData .singleSelection table tbody tr * {
  cursor: pointer !important;
}
.dx-g-bs4-table-summary-item div {
  float: right;
}

.infoCommessa fieldset > *:first-child {
  background-color: rgb(44, 62, 80);
  color: #fff;
}
.infoCommessa fieldset > *:last-child {
  background-color: rgb(52, 73, 94);
  color: #fff;
}
.infoCommessa fieldset > *:nth-child(2) {
  background-color: rgb(52, 73, 94);
  color: #fff;
}
.infoCommessa fieldset > *:last-child {
}
.infoCommessa > div:first-child > div:first-child {
  background-color: rgb(44, 62, 80);
  border-top-left-radius: 0.25rem;
  color: #fff;
}
.infoCommessa > div:nth-child(2) > div:first-child {
  background-color: rgb(44, 62, 80);
  color: #fff;
}
.infoCommessa > div:first-child > div:last-child {
  background-color: rgb(52, 73, 94);
  border-bottom-left-radius: 0.25rem;
  color: #fff;
}
.infoCommessa > div:nth-child(2) > div:last-child {
  background-color: rgb(52, 73, 94);
  color: #fff;
}
.infoCommessa > div:last-child > div:first-child {
  background-color: rgb(44, 62, 80);
  border-top-right-radius: 0.25rem;
  color: #fff;
}
.infoCommessa > div:last-child > div:last-child {
  background-color: rgb(52, 73, 94);
  border-bottom-right-radius: 0.25rem;
  color: #fff;
}
.infoCommessa .imageInTable {
  max-height: 50px !important;
}

.versione {
  font-size: x-small;
  margin-bottom: 0;
}

.versioneLogin {
  font-size: small !important;
  margin-bottom: 0 !important;
}

.testing.header {
  border-top: 3px #b74f0d solid;
}
.mobile .dx-g-bs4-toolbar input.form-control.w-25 {
  width: 100% !important;
}
.documento.mb-2 {
  margin-bottom: 0px !important;
}
button .badge {
  margin-left: 5px;
}
.linkHelper {
  cursor: pointer;
}
.linkHelperInline {
  text-align: center !important;
  font-size: 1rem !important;
  letter-spacing: 0px !important;
  opacity: 1 !important;
  font-weight: normal !important;
  margin-right: 1rem !important;
}
#root .form-control.inputService {
  width: 0px !important;
  height: 0px !important;
  padding: 0 !important;
  position: fixed;
  left: 0;
  top: 0;
}
.d-block.hideModal {
  display: none !important;
}
.ms-n1 {
  margin-left: -0.25rem !important;
}
.ms-n2 {
  margin-left: -0.5rem !important;
}
.listElementCircle {
  cursor: pointer;
}
.listElementCircle:hover {
  z-index: 100000;
}

/* react-resizable required styles */
.react-resizable {
  position: relative;
}
.react-resizable-handle {
  width: 20px !important;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
  background-position: center;
  position: sticky;
  left: 100%;
  top: 100%;
}
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

/* EDITTABLE */

.editedRow {
  border: 2px solid rgba(153, 0, 0, 0.5) !important;
}
.editedRow td:first-child {
  background-color: rgba(153, 0, 0, 0.5) !important;
}
.editedRow4Table {
  border-collapse: collapse !important;
}

.coveredInput:hover,
.coveredInput:focus {
  z-index: 999 !important;
  width: 200px !important;
  position: absolute !important;
}

.btn.btn-primary:disabled {
  background-color: #1d1e1b !important;
  border-color: #000000 !important;
}
.btn-primary.btn:disabled {
  background-color: #1d1e1b !important;
  border-color: #000000 !important;
}

/* css x button aggiunte in vista*/
/*
.customPaddingColumnLeft{
    padding-left: 3px !important;
}

.customPaddingColumnRight{
    padding-right: 3px !important;
}*/

/********************************/

/* JETagInput custom border for erase button input*/

.customStyleTagInputButtonErase {
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  transition: all 0.5s;
}

.customStyleTagInputButtonErase:hover {
  transition: all 0.5s;
  background-color: #e1e1e1 !important;
}

.customBorderRadiusEraseInput > * {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.modal-body,
body,
#root {
  -ms-overflow-style: 0; /* Internet Explorer 10+ */
  scrollbar-width: 0; /* Firefox */
}
.modal-body::-webkit-scrollbar,
body::-webkit-scrollbar,
#root::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.flexCenterContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.collapseCustomPadding {
  padding-top: 0.5rem;
}

/* #region REGISTRAZIONEACCESSI */

.accessiContainer {
  height: 90vh;
  width: 100vw;
  position: relative;
  overflow: hidden;
}

.accessiPrimaryRow {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.accessiSecondaryRow {
  height: 5%;
}

.accessiIN {
  background-color: rgba(0, 128, 0, 0.8);
  color: whitesmoke !important;
}

.accessiOUT {
  background-color: rgba(230, 0, 0, 0.8);
  color: whitesmoke !important;
}

.accessiInfoText {
  text-align: center;
  display: inline-block;
  padding: 0 20% 0 20%;
  font-size: 2.5vw;
}

.accessiBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15vw;
  width: 25vw;
  border: solid 2px #7f8c8d;
  color: #646464;
  font-size: 9vw;
}

.errorLockingToaster {
  z-index: 1000 !important;
  position: fixed !important;
  top: 25% !important;
  right: 15% !important;
  bottom: 25% !important;
  left: 15% !important;
}

.toasterXL {
  font-size: xx-large !important;
  width: 100% !important;
}
.colorWhite {
  color: #ffffff !important;
}
.tagInput {
  width: 100%;
  border: 0;
  outline: 0;
  color: rgb(60, 66, 87);
  background-color: rgba(255, 255, 255, 0);
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding: 4px 8px;
  min-height: 28px;
  vertical-align: middle;
  transition: background-color 0.24s, box-shadow 0.24s;
  transition-property: background-color, box-shadow;
  transition-duration: 0.24s, 0.24s;
  transition-timing-function: ease, ease;
  transition-delay: 0s, 0s;
}

.tagInput:focus {
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(58 151 212 / 36%) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
}

@keyframes blinkElement {
  0% {
    color: rgba(0, 0, 0, 0.7);
  }
  25% {
    color: rgba(0, 0, 0, 0.3);
  }
  50% {
    color: rgba(0, 0, 0, 0.1);
  }
  75% {
    color: rgba(0, 0, 0, 0.3);
  }
  100% {
    color: rgba(0, 0, 0, 0.7);
  }
}

.blinkingIcon {
  animation-name: blinkElement;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

/* #endregion REGISTRAZIONEACCESSI */

/* #region ICONS */

.x-smallIcon {
  margin: auto;
  font-size: small;
}

.smallIcon {
  margin: auto;
  font-size: medium;
}

.mediumIcon {
  margin: auto;
  font-size: large;
}
/* #endregion ICONS */

/* #region DEVELOPMENT_MODE */

.sidebar {
  z-index: 10001 !important;
}

.header.header.header-sticky {
  z-index: 10000 !important;
}

.barcodeScannerDevMode {
  width: calc(100% - 300px) !important;
}

.d-initial {
  display: initial;
  width: initial;
  height: initial;
}

.tooltip {
  z-index: 16500 !important;
}

.toast-container {
  z-index: 15500 !important;
}

.modal-backdrop {
  z-index: 14500 !important;
}

.modal {
  z-index: 15000 !important;
}

.dev_containerTooltip:hover .dev_toolTip {
  display: block;
}

.dev_toolTip {
  position: fixed;
  background-color: #3d4b64;
  color: #fff;
  border-radius: 2px;
  width: auto;
  height: auto;
  white-space: nowrap;
  z-index: 10000 !important;
  cursor: pointer;
}

@media (min-width: 0px) {
  .dev_alertMsg {
    position: fixed;
    width: 15%;
    top: 0;
    margin: 4px 0 0 0;
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    font-size: smaller;
    z-index: 10001;
    white-space: nowrap;
    overflow: hidden;
  }
}

@media (min-width: 800px) {
  .dev_alertMsg {
    position: fixed;
    width: auto;
    top: 0;
    margin: 4px 0 0 0;
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    font-size: smaller;
    z-index: 10001;
    white-space: nowrap;
    overflow: hidden;
  }
}

/* #endregion DEVELOPMENT_MODE */

.mb-1.carico,
.mb-1.sosta {
  margin-bottom: 0.25rem !important;
}

.fs-10 {
  font-size: 0.75rem;
}

.footer {
  font-size: 0.675rem;
}

.link-ucRicerca {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
}
.link-ucRicerca input {
  cursor: pointer;
}
.link-underline-opacity-0 {
  --cui-link-underline-opacity: 0;
  text-decoration: none !important;
}
.link-underline {
  --cui-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(
    var(--cui-link-color-rgb),
    var(--cui-link-underline-opacity, 1)
  ) !important;
  text-decoration-color: rgba(
    var(--cui-link-color-rgb),
    var(--cui-link-underline-opacity, 1)
  ) !important;
}
.link-offset-2 {
  text-underline-offset: 0.25em !important;
}
.link-underline-primary {
  --cui-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(
    var(--cui-primary-rgb),
    var(--cui-link-underline-opacity)
  ) !important;
  text-decoration-color: rgba(
    var(--cui-primary-rgb),
    var(--cui-link-underline-opacity)
  ) !important;
}
.link-underline-secondary {
  --cui-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(
    var(--cui-secondary-rgb),
    var(--cui-link-underline-opacity)
  ) !important;
  text-decoration-color: rgba(
    var(--cui-secondary-rgb),
    var(--cui-link-underline-opacity)
  ) !important;
}

/*Dashboard Macchine */
.dashboardMacchine .card-body {
  padding: 0.4rem;
}
.dashboardMacchine p {
  margin-bottom: 0.2rem;
}
.dashboardMacchine .card-body h3 {
  padding-top: 0.18rem;
}
.dashboardMacchine .card-header {
  text-transform: uppercase;
}
.dashboardMacchine .headerTable .card {
  margin-bottom: 0px;
}
.dashboardMacchine .card {
  margin-bottom: 0.5rem;
}
.dashboardMacchine .row {
  --cui-gutter-x: 0.5rem;
}
.dashboardMacchine .valore-con-um .valore {
  display: inline-block;
  position: relative;
}
.dashboardMacchine .valore-con-um .um {
  position: absolute;
  bottom: 16px;
}
.dashboardMacchine .sezione-messaggio {
  display: flex;
  align-items: center;
  column-gap: 6px;
}
.dashboardMacchine .sezione-messaggio .messaggio {
  font-size: 1.75em;
}
.dashboardMacchine .card-header {
  font-weight: 700;
  font-size: 1rem;
}
.dashboardMacchine .bg-MES-giallo-light {
  background-color: #ffc312;
}
.dashboardMacchine .bg-MES-giallo-dark {
  background-color: #f79f1f;
}
.dashboardMacchine .bg-MES-celeste-light {
  background-color: #81ecec;
}
.dashboardMacchine .bg-MES-celeste-dark {
  background-color: #00cec9;
}
.dashboardMacchine .bg-MES-verde-light {
  background-color: #1dd1a1;
}
.dashboardMacchine .bg-MES-verde-dark {
  background-color: #10ac84;
}
.dashboardMacchine .bg-MES-crema-light {
  background-color: #ffeaa7;
}
.dashboardMacchine .bg-MES-crema-dark {
  background-color: #fdcb6e;
}
.dashboardMacchine .bg-MES-blue-light .card-header {
  background-color: #487eb0;
}
.dashboardMacchine .bg-MES-blue-dark .card-header {
  background-color: #40739e;
}
.dashboardMacchine .color-MES-rosso {
  color: #eb2f06;
}
.dashboardMacchine .color-MES-bianco .card-header {
  color: #fff;
}
.dashboardMacchine .sezione-pianificazioni .descrizione {
  height: calc(100% - 0.5rem);
}
.dashboardMacchine .sezione-pianificazioni .descrizione .testo {
  font-size: 0.95rem;
}

.dashboardMacchine .sezione-fermi .card-header {
  font-size: 0.8rem;
}
.dashboardMacchine .sezione-fermi .card-body h3 {
  font-size: 1rem;
}
.dashboardMacchine .sezione-fermi .bg-MES-blue-dark {
  background-color: #40739e;
}
.dashboardMacchine .sezione-fermi .color-MES-bianco {
  color: #fff;
}
.dashboardMacchine .sezione-fermi .card-header {
  text-transform: none;
}
.dashboardMacchine .sezioneIndicatori h3 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0;
}
.chartjs-tooltip {
  --cui-chartjs-tooltip-zindex: 1080;
  --cui-chartjs-tooltip-padding-x: 0.5rem;
  --cui-chartjs-tooltip-padding-y: 0.25rem;
  --cui-chartjs-tooltip-color: var(--cui-body-bg);
  --cui-chartjs-tooltip-bg: rgba(var(--cui-emphasis-color-rgb), 0.8);
  --cui-chartjs-tooltip-border-radius: var(--cui-border-radius);
  --cui-chartjs-tooltip-transition: all 0.15sease;
  --cui-chartjs-tooltip-header-margin: 0.5rem;
  --cui-chartjs-tooltip-header-font-size: 0.875rem;
  --cui-chartjs-tooltip-header-font-weight: 700;
  --cui-chartjs-tooltip-body-font-size: 0.875rem;
  --cui-chartjs-tooltip-body-font-weight: 400;
  position: absolute;
  z-index: var(--cui-chartjs-tooltip-zindex);
  padding: var(--cui-chartjs-tooltip-padding-y)
    var(--cui-chartjs-tooltip-padding-x);
  color: var(--cui-chartjs-tooltip-color);
  pointer-events: none;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  transform: translate(-50%);
  border-radius: 0.375rem;
  transition: var(--cui-chartjs-tooltip-transition);
}

/* Override css default per evitare che i popover nei filtri della griglia escano dal lato sinistro dello schermo 
.popover.show.bs-popover-bottom {
  z-index: 10002 !important;
}
*/



.zoomx3 input.form-control{
  font-size: large!important;
  padding: 1rem!important;
}
.zoomx3 .input-group .btn.btn-success, 
.zoomx3 .input-group .btn.btn-secondary,
.zoomx3  .input-group .btn.btn-danger{
  font-size: large!important;
  width: auto!important;
  height: auto!important;
}
.zoomx4 input.form-control{
  font-size: x-large!important;
  padding: 1.5rem!important;
}
.zoomx4 .input-group .btn.btn-success, 
.zoomx4 .input-group .btn.btn-secondary,
.zoomx4  .input-group .btn.btn-danger{
  font-size: x-large!important;
  width: auto!important;
  height: auto!important;
}